<template>
  <div class="video-gallery">
    <div v-for="video in videos" :key="video.id" class="video-item">
      <video :src="video.video_path" :poster="video.cover_path" controls></video>
      <div class="video-info">
        <p>{{ video.video_desc }}</p>
        <p>Duration: {{ video.video_seconds }} seconds</p>
      </div>
    </div>
    <div class="pagination">
      <button @click="fetchVideos(page - 1)" :disabled="page <= 1">Previous</button>
      <span>Page {{ page }} of {{ totalPages }}</span>
      <button @click="fetchVideos(page + 1)" :disabled="page >= totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'VideoGallery',
  data() {
    return {
      videos: [],        // 存储视频记录
      page: 1,           // 当前页码
      totalPages: 1,     // 总页数
      pageSize: 10,      // 每页显示的视频数量
      userid: ''
    };
  },
  methods: {
    // 获取视频数据的函数，需要根据实际情况实现
    getVideoListByUserId(userId, pageNum) {
      // 构造请求URL
      const url = `/video/list?userId=${userId}&pageNum=${pageNum}`;
      // 发送GET请求
      axios.get(url).then(response => {
            // 请求成功，处理返回的数据
            if (response.data.code ===200) {
              console.log('视频列表展示成功:', response.data.data);
            } else {
              console.error('获取视频列表失败:', response.data.message);
            }
          })
          .catch(error => {
            // 请求失败，处理错误
            console.error('请求出现错误:', error);
          });
    }
  },
  created() {
    // 组件创建完成后立即获取第一页的数据
    this.getVideoListByUserId('25');
  }
};
</script>

<style scoped>
.video-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.video-item {
  margin: 10px;
  width: 300px;
}

.video-info {
  text-align: left;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
}

button {
  margin: 0 10px;
}
</style>
